@keyframes hover {
  0% {
    transform: translateY(0.5%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0.5%);
  }
}

@keyframes fade-in {
  from {
    transform: translateY(5%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

html {
  box-sizing: border-box;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body,
html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  background-color: white;
  color: gray;
  font-family: sans-serif;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 5em;
  background-color: white;
}

.Logo {
  display: block;
  text-align: center;
  animation: hover 2s ease-in-out infinite;
  user-select: none;
}

.Shell {
  opacity: 0;
  max-width: 75%;
  transform: translate3d(0);
  backface-visibility: hidden;
  user-select: none;

  &--active {
    animation: fade-in 2s ease-out;
    animation-fill-mode: forwards;
  }
}

input {
  appearance: none;
  font-family: sans-serif;
  font-size: 1.5rem;
  width: 100%;
  display: block;
  margin: 0.5em auto;
  padding: 0.25em 0.5em;
  border: none;
  color: black;
  text-align: center;
}

input[type="email"] {
  background-image: url(./underline.png);
  background-repeat: no-repeat;
  background-position: bottom left;
  padding: 0.75em;
}

input[type="email"]:focus {
  outline: 0;
}

input[type="submit"] {
  cursor: pointer;
}

input[type="submit"]:hover {
  color: gray;
}
